




































import {Component} from 'vue-property-decorator';
import AbpBase from '@/lib/abpbase'
import PageRequest from '@/store/entities/page-request'
import EmailTemplateForm from './emailtemplate-form.vue'
import SortingModel from "@/store/entities/sortingmodel";

@Component({
    components: {EmailTemplateForm}
})
export default class EmailTemplate extends AbpBase {

    pageRequest: PageRequest = new PageRequest();

    async changeSort(data) {
        if (this.pageRequest.sorting == null) {
            this.pageRequest.sorting = [];
        }
        let item = this.pageRequest.sorting.find(x => x.fieldName === data.key)
        if (item == null) {
            let sortingModel = new SortingModel();
            sortingModel.fieldName = data.key;
            sortingModel.isDescendingDirection = data.order === "desc";
            this.pageRequest.sorting.push(sortingModel)
        } else {
            item.isDescendingDirection = data.order === "desc";
        }
        await this.getPage()
    }

    edit() {
        this.editModalShow = true;
    }


    createModalShow: boolean = false;
    editModalShow: boolean = false;

    get list() {
        return this.$store.state.emailTemplate.list;
    };

    get loading() {
        return this.$store.state.emailTemplate.loading;
    }

    create() {
        this.createModalShow = true;
    }

    async pageChange(page: number) {
        this.$store.commit('emailTemplate/setCurrentPage', page);
        await this.getPage();
    }

    async search() {
        await this.pageChange(1);
    }

    async pageSizeChange(pageSize: number) {
        this.$store.commit('emailTemplate/setPageSize', pageSize);
        await this.getPage();
    }

    async getPage() {

        this.pageRequest.maxResultCount = this.pageSize;
        this.pageRequest.skipCount = (this.currentPage - 1) * this.pageSize;

        await this.$store.dispatch({
            type: 'emailTemplate/getAll',
            data: this.pageRequest
        })
    }

    get pageSize() {
        return this.$store.state.emailTemplate.pageSize;
    }

    get totalCount() {
        return this.$store.state.emailTemplate.totalCount;
    }

    get currentPage() {
        return this.$store.state.emailTemplate.currentPage;
    }

    columns = [{
        title: this.L('Name'),
        key: 'name',
        sortable: 'custom',
    }, {
        title: this.L('Code'),
        key: 'code',
        sortable: 'custom'
    }, {
        title: this.L('Actions'),
        key: 'Actions',
        width: 150,
        render: (h: any, params: any) => {
            return h('div', [
                h('Button', {
                    props: {
                        type: 'primary',
                        size: 'small'
                    },
                    style: {
                        marginRight: '5px'
                    },
                    on: {
                        click: async () => {
                            this.$store.commit('emailTemplate/edit', await this.$store.dispatch({
                                type: 'emailTemplate/get',
                                data: params.row.id
                            }));
                            this.edit();
                        }
                    }
                }, this.L('Edit')),
                h('Button', {
                    props: {
                        type: 'error',
                        size: 'small'
                    },
                    on: {
                        click: async () => {
                            this.$Modal.confirm({
                                title: this.L('Tips'),
                                content: this.L('Delete email template confirm'),
                                okText: this.L('Yes'),
                                cancelText: this.L('No'),
                                onOk: async () => {
                                    await this.$store.dispatch({
                                        type: 'emailTemplate/delete',
                                        data: params.row
                                    })
                                    await this.getPage();
                                }
                            })
                        }
                    }
                }, this.L('Delete'))
            ])
        }
    }]

    async created() {
        await this.getPage();
    }
}
