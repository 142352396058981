






import 'codemirror/lib/codemirror.css'
import 'codemirror/lib/codemirror.js'
import {Component, Prop} from "vue-property-decorator";
import AbpBase from "../../lib/abpbase";
import codemirror from 'vue-codemirror/src/codemirror.vue'

@Component({
    components: {
        codemirror
    }
})
export default class CodeMirror extends AbpBase {
    @Prop() value: string;

    private cmOptions: object = {
        mode: 'text/html',
        lineNumbers: true,
        line: true
    };

    get inputValue() {
        return this.value;
    }

    set inputValue(newValue) {
        this.$emit('input', newValue);
    }
}
