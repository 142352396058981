




































































import {Component, Prop} from 'vue-property-decorator';
import Util from '../../../../lib/util'
import AbpBase from '../../../../lib/abpbase'
import EmailTemplate from '../../../../store/entities/notificationcontext/emailtemplate'
import PropertyDescriptionDto from "@/store/entities/jobcontext/propertydescription";
import Wysiwyg from '../../../../components/Tinymce/tinymce.vue'
import CodeMirror from "@/components/Codemirror/codemirror.vue";
import EmailTemplateDynamicPropertyForm from './email-template-dynamic-property.vue'
import EmailTemplateDynamicProperty from "@/store/entities/notificationcontext/email-template-dynamic-property";

@Component({
  components: {
    Wysiwyg,
    CodeMirror,
    EmailTemplateDynamicPropertyForm
  }
})
export default class EmailTemplateForm extends AbpBase {
  @Prop({type: Boolean, default: false}) value: boolean;
  @Prop({type: Boolean, default: false}) public toEdit: boolean;
  emailTemplate: EmailTemplate = new EmailTemplate();
  properties: Array<PropertyDescriptionDto> = new Array<PropertyDescriptionDto>();
  boundCompanies: any;

  data() {
    return {
      boundCompanies: []
    }
  }

  addDynamicProperty() {
    this.emailTemplate.dynamicProperties.push(new EmailTemplateDynamicProperty());
  }

  save() {
    (this.$refs.emailTemplateForm as any).validate(async (valid: boolean) => {
      if (valid) {
        await this.$store.dispatch({
          type: this.toEdit ? 'emailTemplate/update' : 'emailTemplate/create',
          data: this.emailTemplate
        });
        (this.$refs.emailTemplateForm as any).resetFields();
        this.emailTemplate.body = '';
        this.$emit('save-success');
        this.$emit('input', false);
      }
    })
  }

  subjectDynamicPropertyChanged(val) {
    if (val) {
      this.emailTemplate.subject = this.emailTemplate.subject + '{{' + val + '}}';
    }
  }

  propertyRemoved(index) {
    this.emailTemplate.dynamicProperties.splice(index, 1);
  }

  cancel() {
    (this.$refs.emailTemplateForm as any).resetFields();
    this.$emit('input', false);
  }

  visibleChange(value: boolean) {
    if (!value) {
      this.$emit('input', value);
    } else {
      if (this.toEdit) {
        this.emailTemplate = Util.extend(true, {}, this.$store.state.emailTemplate.editEmailTemplate);
      } else {
        this.emailTemplate = new EmailTemplate();
      }
    }
  }

  async mounted() {
    this.properties = await this.$store.dispatch('emailTemplate/getJobAvailableProperties');
    this.boundCompanies = await this.$store.dispatch('emailTemplate/getJobsCompanies');
  }


  emailTemplateRule = {
    name: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Name')), trigger: 'blur'}],
    code: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Code')), trigger: 'blur'}],
    body: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Body')), trigger: 'blur'}],
    subject: [{
      required: true,
      message: this.L('FieldIsRequired', undefined, this.L('Subject')),
      trigger: 'blur'
    }]
  }
}
